<script setup>

import sportage from '@/assets/images/wall/WWW_500x300_sportage.jpg';
import tucson from '@/assets/images/wall/WWW_500x300_nowy_tucson.jpg';
import mg from '@/assets/images/wall/WWW_500x300_nowy_ms_hg.jpg';
import ducato from '@/assets/images/wall/WWW_500x300_ducato.png';
import bmw from '@/assets/images/wall/WWW_500x300_bmw3.png';

const cars = ref([]);
const title = ref('Hity października')
const carsFound = ref(false);

const carDefs = ref([
  {brand: 'KIA', model: 'Sportage', body: 'SUV', url: '/wybierz/kia/sportage', img: sportage},
  {brand: 'Hyundai', model: 'Tucson', body: 'SUV', url: '/wybierz/hyundai/tucson', img: tucson},
  {brand: 'MG', model: 'HS', body: 'SUV', url: '/wybierz/mg/hs', img: mg},
  {brand: 'BMW', model: 'Seria 3', body: 'sedan', url: '/wybierz/bmw/seria-3', img: bmw},
  {brand: 'Fiat', model: 'Ducato', body: 'furgon', url: '/wybierz/fiat/ducato', img: ducato},
]);

onMounted(async () => {
  const tmpCars = [];
  const rawCars = toRaw(carDefs.value)

  for(let i=0; i<rawCars.length; i++) {
    const carDef = carDefs.value[i];

    const car = await useBackend().getModels({
      model: carDef.model,
      bodyType: carDef.body
    });

    const found = car.data.find(i => i.count > 0);
    tmpCars.push(found)
  }

  fisherYates(tmpCars);
  carsFound.value = true

  cars.value = tmpCars.filter(i => i);
})

</script>

<template>
  <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6">
    <div class="py-6 text-center md:text-left">
      <h3 class="text-2xl inline leading-7" >
        {{title}}
      </h3>
      <span class="ml-1 font-extralight text-lg">({{cars.length}})</span>
    </div>
  </div>
  <template v-if="carsFound">
    <template v-for="(car,index) in cars">
      <CarTile :car="car" :bg="index===0 ? carDefs.find(i => i.model === car.modelName) : null" />
    </template>
  </template>
  <template v-else>
    <template v-for="index in 6">
      <SkeletonCarTile />
    </template>
  </template>
</template>
